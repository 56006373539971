const mutations = {
    SetLoding(state,buff){
        state.isLoading=buff
    }
    ,SetUuss(state,buff){
        console.log(state,buff,'state,buffstate,buffstate,buffstate,buff');
        state.uuss=buff
    }
    ,SetMenu(state,data){
        state.routers=data
        window.localStorage.setItem('routers',JSON.stringify(data))
    }
    ,Topmenu(state,data){
        state.Toplist=data
        window.localStorage.setItem('Toplist',JSON.stringify(data))
    }
    ,addtoken(state,data){
        console.log(data,'datadatadatadatadatadatadatadata');
        state.uutoken=data
        window.localStorage.setItem('uutoken',data)
    }
    ,Setuuep(state,data){
        console.log(data,'datadatadatadatadatadatadatadata');
        if (!data) {
           return false; 
        }
        state.uuep=data
        window.localStorage.setItem('uuep',data)
    }
    ,setlogin(state,data){

        state.login={
            user:data.username,
            pwd:data.password
          }
    }
    ,cleartoken(state){
        window.sessionStorage.removeItem("loaded")
        // window.localStorage.clear()
        state.uutoken=''
    }
}
export default mutations