import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from "@/utils/util"; 
import store from "../store/index"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: _to => {
      return {
        path: '/login2'
      }
    },
  }, 
  {
    path: '/login2',  
    component: () => import(/* webpackChunkName: "login1" */ '../views/Login_2.vue'),
    redirect: _to => {
      return {
        name: 'Logoin'
      }
    },
    children: [
      {
        path: '/login1',
        name: 'Logoin',
        component: () => import(/* webpackChunkName: "login1" */ '../views/Login_1.vue')
      },
      {
        path: '/ResetPwd',
        component: () => import(/* webpackChunkName: "ResetPwd" */ '../views/ResetPwd.vue')
      },
      {
        path: '/forgetPwd',
        component: () => import(/* webpackChunkName: "forgetPwd" */ '../views/forgetPwd.vue')
      }, 
    ]
  },
 
  {
    path: '/Authority', 
    component: () => import(/* webpackChunkName: "Authority" */ '../views/Authority.vue')
  },
  {
    path: '/Platform', 
    component: () => import(/* webpackChunkName: "Platform" */ '../views/Platform.vue')
  },
  {
    path: '/Platform_db', 
    component: () => import(/* webpackChunkName: "Platform_db" */ '../views/Platform_db.vue')
  },
  
  {
    path: '*',
    component:() => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = new VueRouter({
  mode:'history',
  base: '/',
  routes
})
router.beforeEach((to, from, next) => {
  if (window.location.hash && window.location.hash === '#/') {
    const newUrl = window.location.href.replace('#/', '');
    history.replaceState({}, '', newUrl);
  }
  let token = getCookie('uutoken'); 
 
    if (token) { 
       store.commit("addtoken", token);
      window.localStorage.setItem(
        "uutoken",
        token
      );
    } 
 
  next()
})

export default router
