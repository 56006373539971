import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './actions'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usersign:'',
    uutoken:window.localStorage.getItem('uutoken') || '',
    routers:JSON.parse(window.localStorage.getItem('routers'))|| '',
    Toplist:JSON.parse(window.localStorage.getItem('Toplist'))|| '',
    isLoading:false,
    uuss:'',
    uuep:'',
    login:{
      user:'',
      pwd:''
    }
  },
  getters,
  mutations,
  actions,
  modules:{
  }
})
