//时间方法
export function timestampToTime(timestamp) {
    let Timestamp;
    //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    if (timestamp.length == 10) {
        Timestamp = timestamp * 1000;
    } else {
        Timestamp = timestamp;
    }
    let date = new Date(Timestamp);
    let Y = date.getFullYear();
    let M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s;
}
export const dateFormat = (date, fmt) => {
        let o = {
            'M+': date.getMonth() + 1, // 月份
            'd+': date.getDate(), // 日
            'h+': date.getHours(), // 小时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
            'S': date.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (let k in o) {
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
        return fmt
    }
//数组去重
export function uniqueArr(arr) {
    return Array.from(new Set(arr))
}

//获取url参数
export function getQueryObject(url) {
    url = url == null ? window.location.href : url
    const search = url.substring(url.lastIndexOf('?') + 1)
    const obj = {}
    const reg = /([^?&=]+)=([^?&=]*)/g
    search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1)
        let val = decodeURIComponent($2)
        val = String(val)
        obj[name] = val
        return rs
    })
    return obj
}
// 传入一个数组
// 按照特定方式格式化
export function sortArr(arr, str) {
    var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;
 
    // 按照特定的参数将数组排序将具有相同值得排在一起
    arr = arr.sort(function(a, b) {
        var s = a[str],
            t = b[str]
        return s < t ? -1 : 1
    })
    if ( arr.length ){
        _tmp = arr[0][str]
    }
    // console.log( arr )
    for (var i in arr) {
        console.log( _tmp)
        if ( arr[i][str] === _tmp ){
            console.log(_tmp)
            _t.push( arr[i] )
        } else {
            _tmp = arr[i][str]
            _arr.push( _t )
            _t = [arr[i]]
        }
    }
    // 将最后的内容推出新数组
    _arr.push( _t )
    return _arr
}
//对象深拷贝
// export function deepClone(origin){
//     var isObject = any => typeof any == 'object' && any != null
//     var isArray = any => Object.prototype.toString.call(any) === '[object Array]'
//     if(!isObject(origin)) return origin
//     var target = isArray(origin) ? [] : {}
//     for (var prop in origin) {
//         if (origin.hasOwnProperty(prop)) {
//             var value = origin[prop]
//             if(isObject(value)){
//                 target[prop] = deepClone(value)
//             }else{
//                 target[prop] = value
//             }
//         }
//     }
//     return target
// }


function GetCookieDomain() {
    var host = location.hostname;
    var ip = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (ip.test(host) === true || host === 'localhost') return host;
    var regex = /([^]*).*/;
    var match = host.match(regex);
    if (typeof match !== "undefined" && null !== match) host = match[1];
    if (typeof host !== "undefined" && null !== host) {
        var strAry = host.split(".");
        if (strAry.length > 1) {
            host = strAry[strAry.length - 2] + "." + strAry[strAry.length - 1];
        }
    }
    return '.' + host;
}
export function setCookie(cname, cvalue, iDay) {          //分别代表cookie名称、cookie值、过期时间
    // var oDate = new Date();                    //当前时间
    // oDate.setDate(oDate.getDate() + iDay);      //当前月的日期+过期时间
    // document.cookie = name + '=' + value + ';expires=' + oDate;
    console.log(cname, cvalue, iDay,'cname, cvalue, iDaycname, cvalue, iDaycname, cvalue, iDay');
    document.cookie = cname + "=" + cvalue + "; expires=" + iDay + "; domain=" + GetCookieDomain() + "; path=/";

}

export function getCookie(cookie_name){
    var allcookies = document.cookie;
    var cookie_pos = allcookies.indexOf(cookie_name);   //索引的长度
  
    // 如果找到了索引，就代表cookie存在，
    // 反之，就说明不存在。
    if (cookie_pos != -1)
    {
        // 把cookie_pos放在值的开始，只要给值加1即可。
        cookie_pos += cookie_name.length + 1;      //这里容易出问题，所以请大家参考的时候自己好好研究一下
        var cookie_end = allcookies.indexOf(";", cookie_pos);
  
        if (cookie_end == -1)
        {
            cookie_end = allcookies.length;
        }
  
        var value = unescape(allcookies.substring(cookie_pos, cookie_end));         //这里就可以得到你想要的cookie的值了。。。
    }
    return value;
}

export function removeCookie(name){
    //name名称，再随便来个值1，后面的才是重点-1，时间过期了，所以就成为了负值
    setCookie(name,1,-1);
 }