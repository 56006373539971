import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../plugins/element-plugin'
import "./assets/css/global.css"
// import "./assets/css/elmenter.css"
// import './utils/global'//全局
// 控制路由表的js文件
// import './permissionRouter.js'
import { MessageBox } from 'element-ui'
import VueCookies from 'vue-cookies'; // 引入cookies 插件
Vue.use(VueCookies);
import CryptoJS from 'crypto-js';
Vue.prototype.CryptoJS = CryptoJS;
Vue.config.productionTip = false
// 1、在js中先加载一下代码，不需要做任何修改

Date.prototype.format = function (format) {
  var o = {
    "M+": this.getMonth() + 1, //month
    "d+": this.getDate(), //day
    "h+": this.getHours(), //hour
    "m+": this.getMinutes(), //minute
    "s+": this.getSeconds(), //second
    "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
    "S": this.getMilliseconds() //millisecond
  }
  if (/(y+)/.test(format)) 
  format = format.replace(RegExp.$1,(this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp("(" + k + ")").test(format))
    format = format.replace(RegExp.$1,RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  return format;
}  
// 2、使用new Date(时间戳).format('yyyy-MM-dd')方法进行转化即可，返回的就是yyyy - MM - dd格式的值。 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
